@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  background-color: rgba(209, 214, 197, 0.35);
  font-weight: 300;
}
#root,
.App {
  height: 100vh !important;
}
#main-body {
  display: flex;
  background-color: #edf1f2;
}
#FullPannel {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  overflow-x: hidden;
}
#LeftScrollableMenus {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 87% !important;
}
#LeftScrollableMenus .menu-container {
  position: absolute;
  inset: 0px;
  overflow: scroll;
  margin-right: -15px;
  margin-bottom: -15px;
}
#RightPannel {
  width: 100% !important;
  height: 100vh;
  overflow-x: hidden;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
}
#Content {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 8px;
  scrollbar-width: thin;
}
.navbar {  
  z-index: 1000;
  transition: padding 250ms;
}
.nav-exp {  
  padding-left: 15rem;
  transition: padding 250ms;
}
.navbar-nav .dropdown .dropdown-menu {
  margin-top: 12px !important;
}
#sidenav {
  position: relative;  
  max-width: 15rem !important;
  height: 100% !important;
  color: white;
  z-index: 1001;
  scrollbar-width: thin;
}
#sidenav .logo {
  width: 40px;
  height: 40px;
}
#sidenav-links {
  list-style-type: none;
  padding: 0 !important;
  border-top: 1px solid grey;
}
#sidenav-links a {
  text-decoration: none;
  color: #cccccc;
}
#sidenav-links a:hover {
  color: #fff;
  font-weight: 500;
}
#sidenav-links svg {
  color: grey;
}
#sidenav-links .nav-link-text {
  margin-left: 1rem;
}
#sidenav-links .sidenav-item .active {
  font-size: 17px;
  font-weight: 600;
  color: #fff !important;
}
#sidenav-links .sidenav-item .active svg {
  color: white;
}
.sidenav-expand {
  width: 15rem;
  transition: width 250ms;
}
.sidenav-collapse {
  width: 6rem;
  transition: width 250ms;
}
.sidenav-collapse #sidenav-links span,
.sidenav-collapse .branding {
  display: none;
}
.sidenav-collapse .sidenav-link,
.sidenav-collapse .branding-area {
  justify-content: center !important;
}
.sidenav-dropdown {
  margin-top: -25px !important;
}

/* Top Navbar custom styles*/
@media (min-width: 992px) {
  .dropdown-menu .dropdown_item-1 {
    transform-origin: top center;
    animation: translateX 300ms 60ms ease-in-out forwards;
  }
  .dropdown-menu .dropdown_item-2 {
    transform-origin: top center;
    animation: translateX 300ms 120ms ease-in-out forwards;
  }
  .dropdown-menu .dropdown_item-3 {
    transform-origin: top center;
    animation: translateX 300ms 180ms ease-in-out forwards;
  }
}

@-moz-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-webkit-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-o-keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }
  80% {
    transform: translateX(-5px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.message-dropdown,
.alert-dropdown {
  margin-right: 50px;
}
.message-dropdown-menu,
.alert-dropdown-menu {
  margin-top: 20px !important;
  width: 20rem;
}
.message-dropdown-menu .message-description,
.alert-dropdown-menu .alert-description {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}
.dropdown-list-image {
  margin-top: -10px;
}
.dropdown-list-image .rounded-circle {
  width: 40px;
  height: 40px;
}
.text-truncate {
  display: block;
  max-width: 220px;
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-triangle-excl {
  margin-top: -3px;
}

#login-container {
  padding: 40px;
}

#login-area {
  margin-left: 8rem;
  margin-right: 8rem;
}

canvas {
  background: white;
  padding: 10px;
}
.pie-chart {
  max-height: 400px !important;
}
.action-icons {
  cursor: pointer;
}
.interlocutor-chat {
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: block;
}
.my-chat {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: block;
  background-color: rgba(209, 214, 197, 0.35);
}
.response-file-input {
  display: none;
}
.chat-file {
  max-width: 280px;
}

/* Responsive CSS (Only XS) */
@media (max-width: 575px) {
  .sidenav-collapse {
    width: 0rem;
  }
  #login-container {
    padding: 30px;
  }
  #login-area {
    margin: 0;
  }
  #login-main {
    width: 95% !important;
  }
  .message-dropdown-menu {
    margin-right: -70px;
  }
  .alert-dropdown-menu {
    margin-left: -170px;
  }

  .asset-form,
  .order-form,
  .install-engineer-form,
  .install-company-form,
  .motor-form,
  .vendor-form,
  .product-form,
  .project-form,
  .pump-controller-form,
  .pump-form,
  .site-survey-form,
  .solar-pv-form,
  .user-form,
  .warehouse-form {
    width: 100% !important;
  }
}

/* Responsive CSS (Only SM) */
@media (min-width: 576px) and (max-width: 767px) {
  .message-dropdown-menu {
    margin-right: -70px;
  }
  .alert-dropdown-menu {
    margin-left: -170px;
  }

  .asset-form,
  .order-form,
  .install-engineer-form,
  .install-company-form,
  .motor-form,
  .vendor-form,
  .product-form,
  .project-form,
  .pump-controller-form,
  .pump-form,
  .site-survey-form,
  .solar-pv-form,
  .user-form,
  .warehouse-form {
    width: 100% !important;
  }
}

.overflow-y-scroll {
  scrollbar-width: thin !important;
}

.navbar-bg {
  background-color: rgb(193, 246, 147);
}
.bg-bluEs {
  background-color: #0c0765;
}
.text-color-b {
  color: #0c0765;
}
.dropdown-item:focus {
  background-color: #0c0765 !important;
}
select:focus {
  border-color: rgb(218, 221, 221) !important;
}
@media (min-width: 768px) {
  .min-max-h {
    height: 24rem !important;
    min-height: 24rem !important;
    max-height: 24rem !important;
  }
  .order-progress-chart {
    height: 40rem !important;
  }
  .navbar {
    padding-left: 5rem;
  }
  .nav-exp {
    padding-left: 15rem;
  }
}